import styled from 'styled-components';
import  {FormGroup ,Label, Input} from "reactstrap";

export const FormConnect = styled(FormGroup)`
box-sizing: border-box;
width: 60%;
margin: auto;
max-width: 100%;
padding: 5px 0;
display: flex;
flex-direction: column;
gap: 30px;
align-items: flex-start;
&.maxWidth {
    width: 100%;
}
&.row {
    flex-direction: row;
    align-items: center;
    gap:10px;
    & > div, & > span{
        width: unset;
    }
    & > label {
        width: 30%;
    }
     & > select, & > input {
        width: 67%;
    }
    @media (max-width: 768px) {
        & > label {
        width: 100%;
    }
     & > select, & > input, & > div {
        width: 100%;
    }
    }
}
&.maxWidth {
    width: 100%;
}
&>h4 {
    text-align: center;
    width: 100%;
}
&> p {
    text-align: justify;
}
@media (max-width: 992px) {
    width: 100%;
}
@media (max-width: 768px) {
    gap :20px;
}
`
export const LabelStyled = styled(Label)`
color: ${props => props.theme.bleuHP};
font-family: "Montserrat", sans-serif;
font-size: 16px !important;
font-style: normal;
font-weight: 500;
line-height: 130%;
margin-bottom: 0 !important;
`

export const SpanRating = styled.span`
color: ${props => props.theme.bleuHP};
font-size: 15px;
width: 100%;
`

export const InputStyled = styled(Input)`
z-index: 1;
border: none;
border-radius: 0;
border-bottom: 1.5px solid ${props => props.theme.bleuHP};
background-color: transparent;
font-size: 18px;
color: ${props => props.theme.bleuHP};
font-family: "Montserrat", sans-serif;
&.white {
    color: #fff;
}
&.formConnect {
    width: 75%;
    @media screen and (max-width: 786px) {
        width: 100%;
    }
}
&::placeholder {
    color: #635b5bab;
}
&:focus {
    background-color: transparent;
    border-color:${props => props.theme.rose};
    box-shadow: 0 0 0 0.25rem #a11c7125;
}
@media screen and (max-width: 576px) {
  font-size: 16px !important;
}
`

export const Mail = styled.div`
color: ${props => props.theme.bleuHP};
& > span {
    color: ${props => props.theme.rose};
}
`   

export const FormConnectInscription = styled(FormGroup)`
width: 80%;
margin: auto;
max-width: 100%;
padding: 5px 0;
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 30px;
&.maxWidth {
    width: 100%;
}
&.w60 {
    &>input {
        width: 60%;
        margin: auto;
        @media screen and (max-width: 1130px) {
            width: 100%;
        }
    }
}
`

export const InputStyled2 = styled.input`
border: none;
border-radius: 0;
border-bottom: 1.5px solid ${props => props.theme.bleuHP};
font-size: 16px;
color: #7b7e81;
font-family: Epilogue, sans-serif;
&.radio {
    background-color: #fff;
    border: 1px solid ${props => props.theme.bleuClair};
    margin: 0;
}
&.shadow2 {
    box-shadow: 0 0 0 0.25rem rgba(10, 65, 120, 0.25);
    border-color: rgb(10, 65, 120);
}
&.radio:checked {
    background-color: ${props => props.theme.bleuClair};
    border-color: ${props => props.theme.bleuClair};
}
&::placeholder {
    color: #635b5bab;
}
&:focus {
    border-color:${props => props.theme.rose};
    box-shadow: 0 0 0 0.25rem #a11c7125;
}
`