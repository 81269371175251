import React from "react";
import { useState} from "react";
import { createPortal } from 'react-dom';
import styled, { useTheme } from "styled-components";
import { RotatingLines } from 'react-loader-spinner'
import {PopUpContainer} from "../DesignSystem/PopUp";

const DivLoading = styled.div`
position: absolute;
transform: translate(-50%, -50%);
top: 50%;
left: 50%;
display: flex;
flex-direction: column;
align-items: center;
background: #fff;
border-radius: 10px;
padding: 50px;
margin: auto;
z-index: 9999;
border: 1px solid ${props => props.theme.bleuHP};
box-shadow: 0 0 3px 0 ${props => props.theme.bleuHP};
&>svg {
  /* position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 0; */
}
&> span {
  color: ${props => props.theme.rose};
}
`;

const Titre = styled.div`
  font-family: Unbounded, sans-serif;
  font-size: 20px;
  text-align: center;
  padding: 10px 0;
  `
const SpanPat = styled.span`
padding: 10px 0;
font-size: 20px;
font-family: Unbounded, sans-serif;
text-align: center;
`
const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

function SpinnerHP() {
    const theme = useTheme();
    const color1 = theme.jaune;
    const color2 = theme.rose;
    const color3 = theme.bleuHP;
    const color4 = theme.onLineCollab;
    const color5 = theme.beigeFonce;
    const color6 = theme.grisHP;
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState(color2);
  let portalElement = null;
  if (typeof document !== 'undefined') {
    portalElement = document.getElementById('portal');
  }
  return (
    <>
     { portalElement && createPortal(
                <PopUpContainer>
                   <DivLoading className="sweet-loading">
                      <Titre>Traitement en cours ...</Titre>
                      <RotatingLines
                      // visible={true}
                      // height="80"
                      // width="80"
                      // ariaLabel="vortex-loading"
                      // wrapperStyle={{}}
                      // wrapperClass="vortex-wrapper"
                      // colors={[color1, color2, color3, color1, color2, color3]}
                      visible={true}
                      height="70"
                      width="70"
                      strokeColor={color3}
                      strokeWidth="5"
                      animationDuration="0.75"
                      ariaLabel="rotating-lines-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      />
                      <SpanPat>Merci de patienter</SpanPat>
                      </DivLoading>
                </PopUpContainer>,
            document.getElementById('portal')
              )} 
 
    
    </>
  );
}

export default SpinnerHP;