import { createGlobalStyle } from 'styled-components'
import 'bootstrap/dist/css/bootstrap.min.css';
import icoPdf from '../../src/images/picto_pdf_r.gif';
import icoCarre from '../../src/images/ico_miniCarreP.webp';

const GlobalStyles = createGlobalStyle`

  * {
    box-sizing: border-box;
    /* overflow: visible; */
    /* word-wrap: break-word; */
  }

  /* html {
    min-height: 100%;
  } */


  body {
    
    line-height: 1.5;
    font-style: normal;
    font-stretch: normal;
    font-family: 'Quicksand', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: normal;
    margin: 0 !important;
    padding: 0;
    overflow-x: hidden; 
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-y: none;
  }

::-webkit-scrollbar {
  width: 1.3rem;
}

::-webkit-scrollbar-thumb {
  border-radius: .4rem;
  background: #797979;
  transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #222224;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
}

  h1,
  h2,
  h3,
  h4,
  p  {
    font-family: 'Montserrat', sans-serif;
    color: ${props => props.theme.bleuHP}; 
    font-size: 19px;
    text-align: left;
  }

  @media only screen and (max-width: 768px) {
      p {
        font-size: 16px;
      }
      a {
        /* font-size: 16px; */
      }
    }

  h3 > a {
    color: inherit !important;
  }
  
.buttonInfo {
cursor: pointer;
background: #fff;
border-radius: 5px;
border-style: solid !important;
border-color: rgba(28, 39, 83, 0.5) !important;
border-width: 1px !important;
min-width: 345px;
max-width: 345px;
min-height: 115px;
max-height: 115px;
padding: 20px 30px;
display: flex;
flex-direction: row;
gap: 10px;
-webkit-box-align: center;
align-items: center;
-webkit-box-pack: center;
justify-content: center;
flex-shrink: 0;
position: relative;
overflow: hidden;
transition: all .3s ease; 
box-sizing: border-box;
color: ${props => props.theme.bleuHP};
text-align: center;
font: 600 17px Quicksand, sans-serif;
text-transform: uppercase;
}
.buttonInfo:hover {
  background: ${props => props.theme.bleuHP};
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
  text-decoration: none;
}
  .contenuFlex {
    display: flex;
    align-items: center;
    align-items: stretch;
  }
  .contenuFlex>div:first-child {
    margin-right: 20pt;
  }
  .cadreExemple, .exemple {
    margin: 10pt auto;
    width: 80%;
    padding: 10pt;
    border: 1px solid #aba796;
    background: #fff;
}
  .bleuSombre, .hBleuSombre, ._bleuSombre { color:#0e1b46; } 
  .italique, .hIt, .hItalique, .it { font-style:italic; }
  ul.chiffres {
    counter-reset: listeChiffres;
  }
  ul.chiffres li * { list-style-type:square; }
  ul.chiffres  li { background:none; counter-increment:listeChiffres; list-style-type:none; }
  ul.chiffres  li:before { content:counter(listeChiffres); 
    content: counter(listeChiffres);
    display: inline-block;
    padding: 0px 3px;
    margin-left: -18px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(51, 51, 51);
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size: 14px;
    margin-right: 5pt;
    width: 23px;
    height: 23px;
    text-align: center;
  }
  .gras {
    font-weight: 700;
  }
  .jaune {
    color: ${props => props.theme.jaune} !important;
  } 
  .rose {
    color: ${props => props.theme.rose} !important;
  } 
  .rouge {
    color: #800 !important;
  }
  .beigeImp { color:#A29887 !important; }

  .marron {
    color: #b79b79 !important;
  }
  .grisBleuClair {
    color: #d4d6de !important;
  }
  .beige {
    color: ${props => props.theme.beigeGraph} !important;
  }
  .beigeSombre {
    color: #c2bda6 !important;
  }
  .beigeClair {
    color: #e2e0d7 !important;
  }
  .grisSombre{
    color: #444 !important
  } 
  .majuscules {
    text-transform: uppercase;
  }
  .sansRetour {
    white-space: nowrap;
  }
  .ter {
  margin: 3px 3px 3px 0;
  padding: 4px 7px;
  background: #dcfd00;
  }

  .citation2 {
    width:100%;
    margin: auto;
    padding: 100px;
    color: ${(props) => props.theme.beigeFonce} !important;
    font-size: 55px;
    font-weight: 600;
    font-family: "Syne", sans-serif;
    font-style: italic;
    text-align: center;
    line-height: normal;
    @media only screen and (max-width: 992px) {
      font-size: 30px;
    }
  }
  .fondBeige {
    background-color: ${props => props.theme.beigeFonce};
  }

  table {
    font-family: Unbounded, sans-serif;
    font-size: 16px;
    @media only screen and (max-width: 500px) {
      font-size: 12px;
    }
    @media only screen and (max-width: 420px) {
      font-size: 11px;
    }
    @media only screen and (max-width: 320px) {
      font-size: 10px;
    }
  }

  p:has(>iframe) {
    overflow: auto;
  }
  div:has(>table), p:has(>table) {
    overflow: auto;
  }

  .tableGrasse {
    font-family: Unbounded, sans-serif;
    width: auto;
    margin: auto;
    text-align: center;
    border-collapse: collapse;	
}
	.tableGrasse tbody {
		overflow: auto;
		max-width: 85vw;
		margin: 0 auto;
		display: block;
	}

.tableGrasse tr:first-child td{
	text-align:center;
	background:#ABA796;
	color:#FFF;
	padding:2pt;
}

.cadre  {
  border: 1px solid #d6d3ca;
  margin: 20px auto;
  padding: 0;
  position: relative;
  background: #fff;
}

.cadreTitre {
  position: relative;
  background: #a29887;
  padding: 8px 10px;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  z-index: 3;
}

.tableGrasse td {
	border:1px solid #ABA796;
	padding:4pt;
}

  li {
    color: ${props => props.theme.bleuHP};
    font-family: 'Montserrat', sans-serif;
    font-size: 19px;
    text-align: left;
    @media only screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  ul.icoPDF > li,
  p.icoPDF {
    list-style-type: none;
    background-image: url(${icoPdf});
    background-repeat: no-repeat;
    background-position: 0px 20%;
    padding-left: 30px;
  } 
  ul.icoCarre > li,
  p.icoCarre {
    list-style-type: none;
    background-image: url(${icoCarre});
    background-repeat: no-repeat;
    background-position: 0px 50%;
    padding-left: 12px;
  }
.pc {
  @media (max-width: 768px) {
        display: none;
    }
}
 


  h1 {
  margin: 20px auto;
  color: ${props => props.theme.grisHP};
  text-align: center;
  font-weight: 600 ;
  font-family: Unbounded, sans-serif;
  font-size: 42px;
  letter-spacing: 0.2rem;
  position: relative;
    @media only screen and (max-width: 768px) {
      font: 600 24px Unbounded, sans-serif; 
    }
  }

  h2 {
    color: #0e1b46;
    font: 600 35px Unbounded, sans-serif;
    position: relative;
    text-align: center;
    margin: 50px auto 60px auto;
    @media only screen and (max-width: 768px) {
    font: 600 24px Unbounded, sans-serif; 
    }
  }

  h2 > a {
    font-size: inherit !important;
  }

  h2::after{
    content: "";
    display: block;
    width: 166px;
    height: 1px;
    background-color: rgba(28, 39, 83, 0.2);
    position: absolute;
    left: 50%;
    bottom: -25px;
    transform: translateX(-50%);
  }

  h3 {
    font-family: Unbounded, sans-serif;
    line-height: 130%;
    font-size: 22px;
    color: ${props => props.theme.bleuHP};
    position: relative;
    padding-left: 45px;
    margin: 45px 0 35px 0;
    text-align: left;
  }
  h3::before {
    content: "";
    display: block;
    width: 40px;
    height: 4px;
    background-color: ${props => props.theme.jaune};
    transform: rotate(-60deg) scale(1, 1);
    position: absolute;
    left: 0px;
    top:10px;
  }

  h4 {
    margin: 25px 0px;
    width: 100%;
    color: #489ea3;
    font-size: 24px;
    text-transform: uppercase;
    @media only screen and (max-width: 768px) {
      font-size: 20px; 
    }
  }  

  .h4 {
    display: block;
    margin: 25px 0px;
    color: #489ea3;
    font-size: 24px;
    text-transform: uppercase;
    @media only screen and (max-width: 768px) {
      font-size: 20px; 
    }
  }
  
  h5 {
    margin: 25px 0px;
    color: ${props => props.theme.beigeFonce};
    font-size: 23px;
    font-weight: 700;
    @media only screen and (max-width: 768px) {
      font-size: 18px; 
    }
  }

  *:focus,
  *:active {
    outline: 0 !important;
  }

a,
button {
  transition: opacity .3s;
}
a {
font-family: inherit;
text-align: left;
color: inherit;
text-decoration: none !important;
cursor: pointer;
}
a:hover {
  text-decoration: underline;
  /* opacity: .9; */
}
 
a:active,
button:hover,
button:active {
  /* opacity: .9; */
}

a[target='_blank'],a[target='self'] {
  color: ${props => props.theme.bleuHP};
  border-bottom: 1px dashed ${props => props.theme.beigeClair};
  text-decoration: none;
}
a[target="_blank"],a[target="_self"] {
  color: ${props => props.theme.bleuHP};
  border-bottom: 1px dashed ${props => props.theme.beigeClair};
  text-decoration: none;
}
a.noUnderline {
  border-bottom: none;
}
a[target='_blank']:hover, a[target='_self']:hover { 
/* ,a:not([href="https://www.haussmann-patrimoine.fr"]):hover { */
  border-bottom: 1px solid ${props => props.theme.beigeClair};
  text-decoration: none;
}
a[href^="https://fr."]:hover {
  border-bottom: 1px solid ${props => props.theme.beigeClair};
  text-decoration: none;
}

a[href^="/"], a[href^="https://www.haussmann-patrimoine.fr"],
a[href^="/"][target="_blank"], a[href^="https://www.haussmann-patrimoine.fr"][target="_blank"] {
  border-bottom: 1px dashed ${props => props.theme.bleuHP};
  color: ${props => props.theme.bleuHP};
}
a[href^="/"]:hover, a[href^="https://www.haussmann-patrimoine.fr"]:hover {
  text-decoration: none;
  border-bottom: 1px solid ${props => props.theme.jaune};
}

.noBorder {
  border: none !important;
}
.noBorder:hover {
  color: inherit !important;
}

//-----------------GLOSSAIRE
glossaire {
    position: relative;
    display: inline-block;
    margin: 0 5px 0 0;
    padding: 0 2px;
}
glossaire:after {
    position: absolute;
    content: "•";
    top: -3px;
    right: -7.8px;
    font-size: 85%;
    color: ${props => props.theme.beigeClair};
}

glossaire:hover {
    background: ${props => props.theme.beigeTresClair};
}

.containerFrame{
    position: relative;
    height: 240rem;
    overflow: hidden;
  }

  .responsiveFrame{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1000rem !important;
    max-height: 1002rem !important;
  }


/* a[href^='/'] {
  color: ${props => props.theme.beigeMenu};
  border-bottom: 2px dashed ${props => props.theme.bleuHP};
} */

  /* img {
    max-width: 100%;
   
  }

  img[src*=base64\\] {
    image-rendering: crisp-edges;
    image-rendering: -moz-crisp-edges;
  } */

 

.navbar{
  --bs-navbar-toggler-border-color: #fff;
  --bs-navbar-toggler-focus-width: 0;
  }
  
input[type="checkbox"] {
width: 1.1em;
height: 1.1em;
border-radius: 0.45em !important;
margin-top: 0;
border-color: rgb(10, 65, 120);
}
input[type="checkbox"]:checked {
border-color: rgb(10, 65, 120);
background-color: rgb(10, 65, 120) ;
}
input[type="checkbox"]:focus {
box-shadow: 0 0 0 0.25rem rgba(10, 65, 120, 0.25);
border-color: rgb(10, 65, 120);
}


//-----------------------------------classes des Contenus :

.bleu {
  color: #489ea3 !important;
}
div.bleu > *{
  color: #489ea3 !important;
}
.bleuF {
  color: ${props => props.theme.bleuHP} !important;
}
.bleuG {
  color: ${props => props.theme.grisHP} !important;
}
.majuscules {
  text-transform: uppercase;
}

.blanc, .hBlanc { color:#FFF !important; }

.center,.centre {
  text-align: center;
}

.mt {
  margin-top: 1rem;
}
.mt2 {
  margin-top: 2rem;
}

.fs19 {
  font-size: 19px;
}
.fs16 {
  font-size: 16px;
}
.fs17 {
  font-size: 17px;
}

.fs14 {
  font-size: 14px;
}

.lienHPjaune {
	position: relative;
	display: inline-block;
	color: #000;
	z-index: 1;
  border: none !important;
	/* text-decoration: underline 2px #c7e92b; */
}

.lienHPjaune::before, .lienHPjaune::after {
	content: '';
    position: absolute;
	  left: -3.5px;
    width: 104%;
    height: 2px;
	  bottom: 6px;
    background: ${props => props.theme.jaune};
    z-index: -1;
}

.lienHPjaune:hover {
	text-decoration: none;
  color: inherit !important;
}

.lienHPjaune:hover::after {
left: -3.5px;
background: ${props => props.theme.jaune};
transition: height 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
height: 12px;
}

.cadreCouleur {
  display: block;
  padding: 10px 12px;
  margin: 20px 0;
}

.fondBeigeTresClair {
  background-color:${props => props.theme.beigeTresClair};
}

.tableChiffres {
	width:100%;
	margin:25px auto;
	border:1px solid #000000;
	font-size:15px;
  font-family: Unbounded, sans-serif;
	border-collapse:collapse;
}

.tableChiffres td {
	border:1px solid #000000;
	padding:1vw;
	vertical-align:middle;
}

.tableChiffres tr:first-child td, .tableChiffres tr.header td{
	background-color:#D7D3C2;
	font-weight:bold;
}

.tableChiffres td + td {
	text-align:center;
}

.tableChiffres {
	width:100%;
	margin:25px auto;
	border:1px solid #000000;
	font-size:15px;
  font-family: Unbounded, sans-serif;
	border-collapse:collapse;
}

.tableChiffres td {
	border:1px solid #000000;
	padding:1vw;
	vertical-align:middle;
}

.tableChiffres tr:first-child td, .tableChiffres tr.header td{
	background-color:#D7D3C2;
	font-weight:bold;
}

.tableChiffres td + td {
	text-align:center;
}

.ant-progress-circle-path {
  stroke: #0bc91b !important; 
  stroke-width: 1rem !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #0bc91b !important;
}

.ant-steps-icon > span > svg {
  fill: ${props => props.theme.bleuHP} !important;
}

.ant-steps-item-subtitle {
  font-size: 12px !important;
  color: #0e1b4670 !important;
}

h3.greenValidation::before {
  background-color: #0bc91b !important;
}

//----------------------------------Breadcrumb
.ant-breadcrumb > ol {
    align-items: center;  
}
.ant-breadcrumb-separator {
    font-size: 20px;
    margin-bottom: -3px;
    color : ${props => props.theme.bleuHP} !important;
}
.ant-breadcrumb > ol > li:last-child > span > a {
    font-weight: 600;
    pointer-events: none;
}

//-----------------------------Pagination ANTD
.ant-table-wrapper .ant-table-pagination.ant-pagination {
    margin: 55px 0;
}
.ant-select-selector {
    border: ${props => props.theme.bleuHP} solid 1px !important;
}
.ant-select-selection-item {
    color: #0e1b46 !important;
    font-size: 19px !important;
    border: none !important;
    font-family: Unbounded, sans-serif;
    min-width: 32px;
    height: 32px;
    line-height: 30px;
    text-align: center;
}

.ant-pagination  {
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.ant-pagination .ant-pagination-item {
    font-family: Unbounded, sans-serif;
}
.ant-pagination .ant-pagination-item-active a{
    color: ${props => props.theme.rose} !important;
    font-size: 19px !important;
    line-height: 48px;
    padding: 0 18px;
}
.ant-pagination .ant-pagination-item-active {
    border-color: ${props => props.theme.bleuHP};
    background-color: #fff;
    border-radius: 50%;
    min-width: 50px;
    height: 50px;
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,.5);
    transition: all 0.3s;
}

.ant-pagination .ant-pagination-item:not(.ant-pagination-item-active):hover {
    background-color: #e8006c23;
    border-radius: 50%;
    min-width: 50px;
    height: 50px;
    line-height: 48px;
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,.5);
    padding: 0 10px;
}

.ant-pagination .ant-pagination-item-active:hover {
    border: none;
}

.ant-pagination .ant-pagination-prev, .ant-pagination .ant-pagination-next {
    border-radius: 50%;
    border-radius: 50%;
    min-width: 32px;
    height: 32px;
    line-height: 29px;  
}
.ant-pagination .ant-pagination-prev:hover, .ant-pagination .ant-pagination-next:hover {
    box-shadow: 0px 2px 10px 0px rgba(0,0,0,.5)  
}

.ant-pagination .ant-pagination-prev:hover .ant-pagination-item-link,.ant-pagination .ant-pagination-next:hover .ant-pagination-item-link {
    background-color: transparent;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    font-size: 25px;
    color: ${props => props.theme.rose};
}

.ant-table-wrapper .ant-table-pagination {
    align-items: center;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: #e8006c42 !important;
}

.anticon > svg {
    fill: ${props => props.theme.rose};
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: ${props => props.theme.bleuHP};
  background-color: ${props => props.theme.bleuHP};
}

.ant-checkbox-checked .ant-checkbox-inner  > input[type="checkbox"]:focus {
    box-shadow: 0 0 0 0.25rem rgba(10,65,120,0.25);
    border-color: rgb(10, 65, 120);
}
/* @media screen and (max-width: 600px) {
  .ant-table {
    display: block;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    display: block;
    width: 100%;
  }
} */
//------------------------------------------------SLIDER HOME
.image-gallery-slide .image-gallery-image.home {
    object-fit: cover;
    height: 100%;
    max-height: 100vh !important;
}

[role=button] {
    cursor: default;
}

.image-gallery-slide .image-gallery-description.home {
    padding: 0;
}

.image-gallery-icon.image-gallery-play-button {
    height: 64px;
    width: 64px;
    top: 74.1%;
    border: #fff 2px solid;
    left: 135px;
    padding: 0;
}
.image-gallery-icon.image-gallery-play-button>svg {
    height: 32px;
    width: 33px;
    stroke-width: 1.5px;
}

.image-gallery.home >div >div > button.image-gallery-icon.image-gallery-left-nav > svg ,
.image-gallery.home >div >div > button.image-gallery-icon.image-gallery-right-nav > svg {
   height: 60px;
}
.image-gallery.home >div >div > button.image-gallery-icon.image-gallery-left-nav > svg > polyline,
.image-gallery.home >div >div > button.image-gallery-icon.image-gallery-right-nav > svg > polyline {
    color: ${props => props.theme.jaune};
    top: 87%;
}
.image-gallery.home >div >div > button.image-gallery-icon.image-gallery-left-nav,
.image-gallery.home >div >div > button.image-gallery-icon.image-gallery-right-nav {
    top: 78%;
    border: #fff 2px solid;
    padding: 0;
}
.image-gallery.home >div >div > button.image-gallery-icon.image-gallery-right-nav {
    left: 210px;
    right: unset;
    
}
.image-gallery.home >div >div > button.image-gallery-icon.image-gallery-left-nav {
    left: 60px;
}
.image-gallery.home >div >div > .image-gallery-bullets {
    bottom: 30px !important;
}
.image-gallery.home >div >div > .image-gallery-bullets .image-gallery-bullet {
    width: 18px;
    height: 18px;
    margin: 0px 7px;
}
.image-gallery.home >div >div > .image-gallery-bullets .image-gallery-bullet.active {
    background: ${props => props.theme.jaune} !important;
    transform: scale(1.4) !important;
}

.image-gallery-svg {
    stroke: #fff !important;
}

  @media only screen and (max-width: 1200px) {
    .image-gallery.home >div >div > button.image-gallery-icon.image-gallery-left-nav,
    .image-gallery.home >div >div > button.image-gallery-icon.image-gallery-right-nav {
      top:90%;
    }
    .image-gallery-icon.image-gallery-play-button {
        top: 86%;  
    }
  }
  @media only screen and (max-width: 992px) {
    .image-gallery.home >div >div > button.image-gallery-icon {
      display: none;
    }

    .image-gallery-slide .image-gallery-image.home {
        height: 100%;
    }

    .image-gallery.home >div >div > .image-gallery-bullets {
        bottom: 25px;
    }
  }
  @media only screen and (max-width: 576px) {
    .image-gallery.home >div >div > .image-gallery-bullets {
    bottom: -38px !important;
    background-color: ${props => props.theme.bleuOverlay};
    padding: 10px 0;
    width: 100%;
  }
  }
  @media only screen and (max-width: 373px) {
    .image-gallery.home >div >div > .image-gallery-bullets {
    bottom: -56px !important;
  }
  }

//---------------------------------------------------SWIPERS
//-------------------------presse
.swiperPresse>.swiper-wrapper{
    transition-timing-function : linear !important;
  }
@media (max-width: 992px) {
  .swiper.swiperPresse  {
    /* height: 1845px;
    padding-top: 140px; */
    }
    .swiper.swiperPresse > .swiper-wrapper {
      align-items: center;
    }
}

//------------------------produits 
.containerProduits {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 768px) {
    /* max-height: 500px; */
  }
}

.swiperProduits {
  width: 100%;
  padding-top: 50px !important;
  padding-bottom: 120px !important;
}

.swiperProduits>div>.swiper-slide {
  width: 370px;
  height: 520px;
  position: relative;
  @media (max-width: 768px) {
    /* width: 200px;
    height: 300px; */
  }
}

.swiperProduits>div>.swiper-slide img {
  width: 100%;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-right: 0;
  object-fit: cover;
  -webkit-box-reflect: below -180px linear-gradient(transparent, transparent, #0000004d);
}

.swiperProduits>div>.swiper-slide-shadow-left,
.swiperProduits>div>.swiper-slide-shadow-right {
  display: none;
}

.swiperProduits>.swiper-pagination .swiper-pagination-bullet {
  background: rgb(217, 217, 217);
  opacity: 1;
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 18px));
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 18px));
}

.swiperProduits>.swiper-pagination .swiper-pagination-bullet:hover {
  background-color: ${props => props.theme.rose};
}

.swiperProduits>.swiper-pagination .swiper-pagination-bullet-active {
  background-color: ${props => props.theme.rose};
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 18px));
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 18px));
}

.swiperProduits>.swiper-wrapper>.swiper-slide.swiper-slide-visible.active {
  transform: rotateY(0deg) !important;
  filter: brightness(1);
}
.swiperProduits>.swiper-wrapper>.swiper-slide.swiper-slide-visible {
  filter: brightness(0.7);
}
.swiperProduits>div>.swiper-slide.swiper-slide-visible.active:hover>.label {
  height: 100%;
}

.swiperProduits>div>.swiper-slide>.label {
  position: absolute;
  bottom: 0;
  background: antiquewhite;
  width: 100%;
  height: 0;
  transition: all 0.4s ease 0s;
  overflow: hidden;
}

@media (max-width: 500px) {
  .containerProduits.immo > .swiperProduits {
  height: auto;
}
  .swiperProduits {
    height: 47rem;
  }
  /* .swiperProduits.immo {
    height: 20rem;
  } */

  .swiperProduits>div>.swiper-slide img {
    width: 100% !important;
    height: 100% !important;
  }
}

//--------------------------------Slider Equipe
.swiperEquipe>.swiper-wrapper{
  transition-timing-function : linear !important;
}

.swiperEquipe>.swiper-pagination .swiper-pagination-bullet {
  background: ${props => props.theme.bleuHP};
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 25px));
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 25px));
}
.swiperEquipe>.swiper-pagination .swiper-pagination-bullet:hover {
  background-color: ${props => props.theme.rose};
  opacity: 1 !important;
}

.swiperEquipe>.swiper-pagination .swiper-pagination-bullet-active {
  width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 25px));
  height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 25px));
}

//-----------------------------------Slider Blog
.swiper.swiperBlog >div >.swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .swiper.swiperBlog >div >.swiper-slide>div {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    height: 240px;
  }

  .swiper.swiperBlog >div>.swiper-slide img {
    display: block;
    width: 100%;
    height: 240px;
    object-fit: cover;
  }

  .swiper.swiperImmo > .swiper-pagination.swiper-pagination-bullets,  
  .swiper.swiperBlog > .swiper-pagination.swiper-pagination-bullets {
    bottom: 40px;
  }

.swiper.swiperImmo > .swiper-pagination > .swiper-pagination-bullet,
.swiper.swiperBlog > .swiper-pagination > .swiper-pagination-bullet {
background: ${props => props.theme.bleuHP};
width: 15px;
height: 15px;
}

.swiperImmo>.swiper-pagination .swiper-pagination-bullet,
.swiperBlog>.swiper-pagination .swiper-pagination-bullet {
width: 30px !important;
height: 30px !important;
}


.swiper.swiperImmo > .swiper-pagination> .swiper-pagination-bullet:hover,
.swiper.swiperBlog > .swiper-pagination> .swiper-pagination-bullet:hover {
background: ${props => props.theme.rose};
opacity: 1;
}

@media (max-width: 992px) {
  .swiper.swiperBlog > .swiper-wrapper {
    align-items: center;
  }
}

//------------------------------------Slider Contact
.image-gallery {
    margin-top: 10px;
    width: 100%;
}

.image-gallery-bullets {
    bottom: -45px !important;
}

.image-gallery-bullets .image-gallery-bullet.active {
  transform: scale(1.7) !important;
  background: ${props => props.theme.bleuHP} !important;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  position: relative;
  border: none;
}

.image-gallery-bullets .image-gallery-bullet {
    background: rgba(217, 217, 217, 0.5) !important;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    position: relative;
    border: none !important;
    box-shadow: none !important;
}

.image-gallery-bullets .image-gallery-bullet:hover,
.image-gallery-bullets .image-gallery-bullet.active:hover {
    background-color: ${props => props.theme.rose} !important;
    border: none;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 100%;
    height: 100%;
    object-fit: cover;
}

.image-gallery-slide.image-gallery-center {
    cursor: initial;
}

@media screen and (max-width: 600px) {
    .image-gallery {
        width: 100%;
        margin: 50px auto;
    }

    .image-gallery-content .image-gallery-slide .image-gallery-image {
        border-radius: 5px;
        max-height: 100vh !important;
    }
    .image-gallery-bullets .image-gallery-bullet {
      margin: 0 8px !important; 
    }
}

//---------------------------------Sliders Produits immo
.swiper.swiperImmo >div>.swiper-slide img {
 object-fit: cover;
}
.swiper.swiperImmo > .swiper-button-prev,
.swiper.swiperImmo > .swiper-button-next {
color: #fff;
background-color: #0000008a;
border-radius: 50%;
width: 60px;
height: 60px;
opacity: .8;
}
.swiper.swiperImmo > .swiper-button-prev:hover,
.swiper.swiperImmo > .swiper-button-next:hover {
color: ${props => props.theme.jaune};
opacity: 1;
}

//-----------------------------------------------------------Swiper Contacts sans conseiller

.swiper.contactSansConseiller > .swiper-button-prev,
.swiper.contactSansConseiller > .swiper-button-next {
top: 3%;
cursor: pointer;
background: #fff;
border-radius: 5px;
border-style: solid;
border-color: ${props => props.theme.bleuHP};
border-width: 1px;
min-width: 140px;
max-width: 305px;
min-height: 50px;
max-height: 65px;
padding: 15px;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
overflow: hidden;
transition: all .3s ease; 
color: ${props => props.theme.bleuHP};
text-align: center;
font: 600 20px Quicksand, sans-serif;
text-transform: uppercase;
text-decoration: none;
}
.swiper.contactSansConseiller > .swiper-button-prev:hover,
.swiper.contactSansConseiller > .swiper-button-next:hover {
  background: ${props => props.theme.bleuHP};
  color: #fff;
}
.swiper.contactSansConseiller > .swiper-button-prev::after,
.swiper.contactSansConseiller > .swiper-button-next::after {
  display: none;
}

@media (max-width: 768px) {
  .swiper.contactSansConseiller > .swiper-button-prev,
  .swiper.contactSansConseiller > .swiper-button-next {
    min-width: 120px;
    font-size: 16px;
    min-height: 40px;
  }
}

//-----------------------------------------------------------MEMBRE tables, forms

.ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child {
    border-start-end-radius: 0px;
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child {
    border-start-start-radius: 0px;
}

.ant-upload-wrapper {
    width: 100% !important;
    display: flex !important;
}
.ant-upload-drag-container {
    width: 100%;
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.ant-upload-text {
    color: ${props => props.theme.rose} !important;
}
.ant-upload-wrapper .ant-upload-drag {
    border: none !important;    
}

textarea:where(.css-dev-only-do-not-override-nz0s15).ant-input {
    height: 100px !important;
}
:where(.css-dev-only-do-not-override-6j9yrn).ant-input {
    color: rgba(0, 0, 0, 0.88);
    font-family: Unbounded;  
}

.ant-select-single{
    width: 90%;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    min-width: 160px !important;
}

.ant-table-wrapper .ant-table-footer{
    background: #195aa01a !important;
}

.ant-picker {
    color: rgb(10, 65, 120);
    text-align: left;
    padding: 12px 8px;
    position: relative;
    /* line-height: 2.3; */
    border-color: rgba(28, 39, 83, 0.5);
    font-size: 1rem !important;
    margin-bottom: 0px !important;
    font-family: Unbounded, sans-serif;
}

.ant-picker-focused.ant-picker{
    background-color: transparent;
    border-color:${props => props.theme.rose} !important;
    box-shadow: 0 0 0 0.25rem #a11c7125 !important;
}
.ant-picker .ant-picker-input >input {
    color: #635b5bab !important;
    font-size: 1rem !important;
    font-family: Unbounded, sans-serif;
}
.ant-picker::placeholder {
    color: #635b5bab !important;
    font-size: 1rem !important;
}

.ant-picker-suffix {
    color: ${props => props.theme.rose} !important;
}
.ant-picker-suffix > span > svg {
    font-size: 22px;
}

// ------------------------------------------------------ Keen slider 

.navigation-wrapper {
  position: relative;
}
.navigation-wrapper.presse {
  padding: 30px 0;
}

.dots {
  position: relative;
  display: flex;
  padding: 40px 0;
  justify-content: center;
  align-items: center;
}
.dots.presse {
  position: relative;
  display: flex;
  padding: 50px 0 0 0;
  justify-content: center;
  align-items: center;
}

.dot {
  border: none;
  width: 15px;
  height: 15px;
  background: ${props => props.theme.grisHP};
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
  transition: background 0.3s;
}
.dot:hover {
  background: ${props => props.theme.rose};
}

.dot:focus {
  outline: none;
}

.dot.active {
  background: ${props => props.theme.bleuHP};
  width: 25px;
  height: 25px;
}

.arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: #000;
  cursor: pointer;
}

.arrow--left {
  left: 5px;
}

.arrow--right {
  left: auto;
  right: 5px;
}

.arrow--disabled {
  fill: rgba(255, 255, 255, 0.5);
}

//-----------------------------------------------------Graphs produit 
.custom-tooltip {
  font-family: 'Unbounded', sans-serif;
  background-color: #ffffffce;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 12px;
  border-radius: 5px;
  box-shadow: 0 0 10px #999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.custom-tooltip > p.label.rose {
  color: ${props => props.theme.rose};
}
.custom-tooltip > p {
  margin: 0;
}

.recharts-legend-item {
    font-size: 15px;
    display: block !important;
}

.custom-chart-container .recharts-surface {
  overflow: visible;
}



@media only screen and (max-width: 768px) {
    img {
    max-width: 100%;
    }
    iframe {
    max-width: 100%;
    max-height: 600px;
    }
  }

// ------------------------------------------------------ ProCli

.ant-radio-wrapper {
  font-size: 18px !important;
  font-family: 'Montserrat', sans-serif !important;
  color: ${props => props.theme.bleuHP} !important;
}

.ant-radio-inner {
  width: 18px !important;
  height: 18px !important;
  border-color: ${props => props.theme.bleuHP} !important;
  border-width: 2px !important;
}

.recharts-legend-item {
    margin-bottom: 10px;
 }
 .recharts-legend-item-text {
    color: ${props => props.theme.bleuHP} !important;
 }

`


export default GlobalStyles
